import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    setProductConfigSteps,
    setProductVariantsList,
    updateProductPageVariant,
    updateProductPageSettings,
    setProductConfigColorStep,
    setProductConfigPaletteStep,
    resetProductPageVariantList,
    addProductPageVariantsToCart,
    addProductPageVariantsToQuote,
    selectProductConfigStepOption,
    selectProductConfigColorStepOption,
    selectProductConfigPaletteStepOption,
    orderSample,
} from './../../../actions/productPageActionCreators';

import InproProvider from './../../InproProvider';
import Step from './Step';
import ColorStep from './ColorStep';
import ShopProductTable from './../../Common/ShopProductTable';
import Cloak from './../../Common/Cloak';
import NumberedContainer from './../../Common/NumberedContainer/';
import NewShopProductTable from './../../Common/ShopProductTable/NewShopProductTable.js';
import NewColorStep from './NewColorStep.js';

class NewConfiguration extends Component {

    constructor(props) {
        super(props);

        const {
            Steps,
            Variants,
            ColorStep,
            PaletteStep,
            MinimumPurchaseAmount,
            tableLabels,
            QuoteList,
            isGuestUser,
            isDiscontinued,
            isAvailableForPurchase,
            hideAddColorSampleToCartButton,
            isPortal,
            isKitLayout,
            enableDropdownDependencies,
            internetPartNumberSymbol,
            positionPaletteColor,
            MarketSegments,
            activeQuoteId,
            activeQuoteProjectName,
        } = props.initialConfigurationData;

        this.state = {
            initialSteps: Steps,
            initialVariants: Variants,
            initialColorStep: ColorStep,
            initialPaletteStep: PaletteStep,
            quoteList: QuoteList ? QuoteList : [],
            marketSegments: MarketSegments ? MarketSegments : [],
            minimumPurchaseAmount: MinimumPurchaseAmount,//TODO: move to store if needed. TBD.
            tableLabels,
            isGuestUser,
            isDiscontinued,
            isAvailableForPurchase,
            hideAddColorSampleToCartButton,
            isPortal,
            isKitLayout,
            enableDropdownDependencies,
            internetPartNumberSymbol,
            activeQuoteId,
            activeQuoteProjectName,
            quantityofNoSKU: 1,
            selectedColorState: "",
            positionPaletteColor
        };
    }

    componentDidMount() {
        const {
            steps,
            variants,
            colorStep,
            paletteStep,
            setSteps,
            setVariants,
            setColorStep,
            setPaletteStep,
            initialConfigurationData,
        } = this.props;

        if (!colorStep && initialConfigurationData) {
            const {
                ColorStep,
            } = initialConfigurationData;

            setColorStep(ColorStep || null);
        }

        if (!steps && initialConfigurationData) {
            const {
                Steps,
            } = initialConfigurationData;

            setSteps(Steps || []);
        }

        if (!paletteStep && initialConfigurationData) {
            const {
                PaletteStep,
            } = initialConfigurationData;

            setPaletteStep(PaletteStep || null);
        }

        if (!variants && initialConfigurationData) {
            const {
                Variants,
            } = initialConfigurationData;

            setVariants(Variants || []);
        }

    }

    checkIsLoading = () => {
        const {
            settings: {
                isColorStepLoading,
                isVariantsLoading,
            },
        } = this.props;

        return isColorStepLoading || isVariantsLoading;

    }

    onOptionChange = (stepName, value) => {
        const { selectStepOption, steps, paletteStep, colorStep, initialConfigurationData, setSteps } = this.props;

        const {
            positionPaletteColor,
        } = this.props.initialConfigurationData;
        var updatedSteps = steps;

        if (initialConfigurationData.enableDropdownDependencies && !initialConfigurationData.isKitLayout) {
            //Dependency Filtering of Options
            var stepsToRender = steps;
            stepsToRender.forEach(function (item, index) {

                if (item.Name === stepName) {



                    if (item.Options != null) {


                        var selectedOption = item.Options.find(o => o.Value === value);

                        if (selectedOption != null) {
                            if (selectedOption.NextOptions != null) {
                                if (selectedOption.NextOptions.length > 0) {

                                    //Set next property's options to show or hide
                                    if (stepsToRender[index + 1] != null) {
                                        if (stepsToRender[index + 1].Options != null) {

                                            var nextPropertyOptions = stepsToRender[index + 1].Options;

                                            nextPropertyOptions.forEach(function (item2, index2) {

                                                if (selectedOption.NextOptions.indexOf(item2.Value) > -1) {
                                                    //If in the array, show it
                                                    stepsToRender[index + 1].Options[index2].Show = true;
                                                }
                                                else {
                                                    //Hide and Deselect
                                                    stepsToRender[index + 1].Options[index2].Show = false;
                                                    stepsToRender[index + 1].Options[index2].Selected = false;
                                                }

                                            });

                                            //if there's no selected, make the first option selected true if show = true
                                            var selectedOptionInNextDropdown = stepsToRender[index + 1].Options.find(o => o.Selected);
                                            if (selectedOptionInNextDropdown == null) {
                                                const indexOfFirstShow = stepsToRender[index + 1].Options.findIndex(a => a.Show == true);
                                                if (indexOfFirstShow > -1) {
                                                    stepsToRender[index + 1].Options[indexOfFirstShow].Selected = true;
                                                }
                                            }


                                        }
                                    }

                                }
                            }
                        }
                    }
                }


            });


            setSteps(stepsToRender || []);
            updatedSteps = stepsToRender;
        }




        //Dependency for Kit layout page
        if (initialConfigurationData.enableDropdownDependencies && initialConfigurationData.isKitLayout) {
            var stepsToRender = steps;

            //Get First Dropdown
            var firstDropdownStep = stepsToRender[0];

            //If first dropdown is changed aka matches stepname
            if (firstDropdownStep != null) {
                if (firstDropdownStep.Name != null) {
                    if (firstDropdownStep.Name === stepName) {

                        //Get Option based on selected value
                        var selectedOption = firstDropdownStep.Options.find(o => o.Value === value);

                        //Apply SKU array comparision to other steps
                        stepsToRender.forEach(function (step, index) {


                            if (index != 0 && step.Options != null) {


                                if (selectedOption != null) {
                                    if (selectedOption.BelongsToThisSKUArray != null) {
                                        if (selectedOption.BelongsToThisSKUArray.length > 0) {

                                            //Set options to show or hide based on sku array
                                            if (stepsToRender[index] != null) {
                                                if (stepsToRender[index].Options != null) {


                                                    var nextPropertyOptions = stepsToRender[index].Options;

                                                    nextPropertyOptions.forEach(function (item2, index2) {

                                                        //Check if option's sku array have a sku in common with the first dropdown sku array

                                                        if (selectedOption.BelongsToThisSKUArray.some(item => item2.BelongsToThisSKUArray.includes(item))) {
                                                            stepsToRender[index].Options[index2].Show = true;
                                                            //stepsToRender[index].Options[index2].Selected = true;
                                                        }
                                                        else {
                                                            stepsToRender[index].Options[index2].Show = false;
                                                            stepsToRender[index].Options[index2].Selected = false;
                                                        }

                                                    });

                                                    //if there's no selected, make the first option selected true if show = true
                                                    var selectedOptionInNextDropdown = stepsToRender[index].Options.find(o => o.Selected);
                                                    if (selectedOptionInNextDropdown == null) {
                                                        const indexOfFirstShow = stepsToRender[index].Options.findIndex(a => a.Show == true);
                                                        if (indexOfFirstShow > -1) {
                                                            stepsToRender[index].Options[indexOfFirstShow].Selected = true;
                                                        }
                                                    }



                                                }
                                            }

                                        }
                                    }
                                }

                            }

                        });

                    }
                }
            }




            setSteps(stepsToRender || []);
            updatedSteps = stepsToRender;
        }


        if (!this.checkIsLoading()) selectStepOption(stepName, value);



        this.setState({
            quantityofNoSKU: 1
        });



        if (initialConfigurationData.isKitLayout) {

            var imageVar;
            if (steps) {
                steps.map(step => {
                    if (step.Name === stepName) {
                        step.Options.map(option => {
                            if (option.Value === value) {
                                imageVar = option.Image;
                            }
                        })
                    }

                })
            }

            if (imageVar != null) {
                this.changeImage(imageVar);
            }


        }

        //Update step value
        if (!initialConfigurationData.enableDropdownDependencies) {
            var stepsToRender = steps;
            stepsToRender.forEach(function (item, index) {

                if (item.Name === stepName) {



                    if (item.Options != null) {

                        item.Options.forEach(function (optionItem, optionIndex) {
                            if (optionItem.Value === value) {
                                stepsToRender[index].Options[optionIndex].Selected = true;
                            }
                            else {
                                stepsToRender[index].Options[optionIndex].Selected = false;
                            }

                        });

                    }
                }


            });
            updatedSteps = stepsToRender;
        }
        
        //Update URL Parameters for fixed URL
        var string = "";
        var positionOfColorAndPalette = 0;
        var selectedPalette = "";
        var selectedColor = "";
        if (paletteStep != null && paletteStep.Options != null) {
            selectedPalette = paletteStep.Options.find(o => o.Selected) != null ? paletteStep.Options.find(o => o.Selected).Label : "";
        }
        if (colorStep != null && colorStep.Options != null) {
            selectedColor = colorStep.Options.find(o => o.Selected) != null ? colorStep.Options.find(o => o.Selected).Label : "";
        }

        var colorPaletteString = "";
        if (selectedPalette && selectedColor) {
            colorPaletteString = "Palette:" + selectedPalette + ";" + "Color:" + selectedColor;
        }
        else if (selectedPalette) {
            colorPaletteString = "Palette:" + selectedPalette;
        }

        if (positionPaletteColor) {
            positionOfColorAndPalette = positionPaletteColor;
        }

        if (updatedSteps) {
            updatedSteps.forEach(function (item, index) {

                //Add Color and Palette
                if (colorPaletteString && index + 1 == positionOfColorAndPalette) {
                    if (string) {
                        string = string + ";" + colorPaletteString;
                    }
                    else {
                        string = colorPaletteString;
                    }
                }

                if (item != null) {
                    //Get the option that is selected if it is a dropdown
                    var selectedOption = "";
                    var found = item.Options.find(element => element.Selected);
                    if (found != null) {
                        selectedOption = found.Label;
                    }
                    if (string) {
                        //string = string + "&" + item.Title + "=" + selectedOption;
                        string = string + ";" + item.Title + ":" + selectedOption;
                    }
                    else {
                        //string = "&" + item.Title + "=" + selectedOption;
                        string = item.Title + ":" + selectedOption;
                    }
                }
            });

            //Add Color and Palette at end if position is 0
            if (colorPaletteString && positionOfColorAndPalette == 0) {
                if (string) {
                    string = string + ";" + colorPaletteString;
                }
                else {
                    string = colorPaletteString;
                }
            }

            if (string) {
                //Add To URL 
                const url = new URL(window.location);
                url.searchParams.set('filters', string);
                window.history.pushState(null, '', url.toString());
            }
        }


    }

    onColorSelect = (value, imageVar, ColorAndSKUFilter, name) => {
        const { selectColor, initialConfigurationData, resetVariants } = this.props;
        if (!this.checkIsLoading()) selectColor(value, initialConfigurationData.showStockAvailability);

        this.setState({
            selectedColorState: value
        });

        //if (imageVar != null && initialConfigurationData.isKitLayout) {
        //  this.changeImage(imageVar);
        //}

        //if (ColorAndSKUFilter) {
        if (!this.checkIsLoading()) resetVariants();
        //}

        const { steps, paletteStep } = this.props;

        const {
            positionPaletteColor,
        } = this.props.initialConfigurationData;
        var updatedSteps = steps;


        //Update URL Parameters for fixed URL
        var string = "";
        var positionOfColorAndPalette = 0;
        var selectedPalette = "";
        var selectedColor = "";
        if (paletteStep != null && paletteStep.Options != null) {
            selectedPalette = paletteStep.Options.find(o => o.Selected) != null ? paletteStep.Options.find(o => o.Selected).Label : "";
        }
        if (name != null) {
            selectedColor = name;
        }

        var colorPaletteString = "";
        if (selectedPalette && selectedColor) {
            colorPaletteString = "Palette:" + selectedPalette + ";" + "Color:" + selectedColor;
        }
        else if (selectedPalette) {
            colorPaletteString = "Palette:" + selectedPalette;
        }

        if (positionPaletteColor) {
            positionOfColorAndPalette = positionPaletteColor;
        }

        if (updatedSteps) {
            updatedSteps.forEach(function (item, index) {

                //Add Color and Palette
                if (colorPaletteString && index + 1 == positionOfColorAndPalette) {
                    if (string) {
                        string = string + ";" + colorPaletteString;
                    }
                    else {
                        string = colorPaletteString;
                    }
                }

                if (item != null) {
                    //Get the option that is selected if it is a dropdown
                    var selectedOption = "";
                    var found = item.Options.find(element => element.Selected);
                    if (found != null) {
                        selectedOption = found.Label;
                    }
                    if (string) {
                        //string = string + "&" + item.Title + "=" + selectedOption;
                        string = string + ";" + item.Title + ":" + selectedOption;
                    }
                    else {
                        //string = "&" + item.Title + "=" + selectedOption;
                        string = item.Title + ":" + selectedOption;
                    }
                }
            });

            //Add Color and Palette at end if position is 0
            if (colorPaletteString && positionOfColorAndPalette == 0) {
                if (string) {
                    string = string + ";" + colorPaletteString;
                }
                else {
                    string = colorPaletteString;
                }
            }

            if (string) {
                //Add To URL 
                const url = new URL(window.location);
                url.searchParams.set('filters', string);
                window.history.pushState(null, '', url.toString());
            }
        }

    }

    onPaletteSelect = (name, value) => {
        const { selectPalette, steps, setSteps } = this.props;

        //reset all options in dropdowns to show
        var stepsToRender = steps;
        stepsToRender.forEach(function (item, index) {

            if (item.Options != null) {

                var options = item.Options;
                options.forEach(function (item2, index2) {
                    stepsToRender[index].Options[index2].Show = true;
                });

            }

        });
        setSteps(stepsToRender || []);

        if (!this.checkIsLoading()) selectPalette(value);





        const {
            positionPaletteColor,
        } = this.props.initialConfigurationData;
        var updatedSteps = stepsToRender;


        //Update URL Parameters for fixed URL
        var string = "";
        var positionOfColorAndPalette = 0;
        var selectedPalette = "";
        var selectedColor = "";


        //find palette name based on value
        const { paletteStep } = this.props;
        if (paletteStep != null && paletteStep.Options != null) {
            var paletteOption = paletteStep.Options.find(o => o.Value == value);
            if (paletteOption != null) {
                selectedPalette = paletteOption.Label;
            }
        }


        var colorPaletteString = "";
        if (selectedPalette && selectedColor) {
            colorPaletteString = "Palette:" + selectedPalette + ";" + "Color:" + selectedColor;
        }
        else if (selectedPalette) {
            colorPaletteString = "Palette:" + selectedPalette;
        }

        if (positionPaletteColor) {
            positionOfColorAndPalette = positionPaletteColor;
        }

        if (updatedSteps) {
            updatedSteps.forEach(function (item, index) {

                //Add Color and Palette
                if (colorPaletteString && index + 1 == positionOfColorAndPalette) {
                    if (string) {
                        string = string + ";" + colorPaletteString;
                    }
                    else {
                        string = colorPaletteString;
                    }
                }

                if (item != null) {
                    //Get the option that is selected if it is a dropdown
                    var selectedOption = "";
                    var found = item.Options.find(element => element.Selected);
                    if (found != null) {
                        selectedOption = found.Label;
                    }
                    if (string) {
                        //string = string + "&" + item.Title + "=" + selectedOption;
                        string = string + ";" + item.Title + ":" + selectedOption;
                    }
                    else {
                        //string = "&" + item.Title + "=" + selectedOption;
                        string = item.Title + ":" + selectedOption;
                    }
                }
            });

            //Add Color and Palette at end if position is 0
            if (colorPaletteString && positionOfColorAndPalette == 0) {
                if (string) {
                    string = string + ";" + colorPaletteString;
                }
                else {
                    string = colorPaletteString;
                }
            }

            if (string) {
                //Add To URL 
                const url = new URL(window.location);
                url.searchParams.set('filters', string);
                window.history.pushState(null, '', url.toString());
            }
        }


    }
    onVariantSelect = (variant) => {
        const { selectVariant, resetVariants } = this.props;
        if (!this.checkIsLoading()) resetVariants(variant);

        if (!this.checkIsLoading()) selectVariant(variant);

    }

    onCloseNotice = () => {
        const settings = {
            showAddToCartNotice: false,
            lastCartName: '',
        };

        const {
            updateSettings,
        } = this.props;

        updateSettings(settings);
    }

    onCloseWarning = () => {
        const settings = {
            showAddToCartWarning: false,
        };

        const {
            updateSettings,
        } = this.props;

        updateSettings(settings);
    }

    onAddToCart = (cartName, cartLabel) => {
        const {
            updateSettings,
            addVariantsToCart,
        } = this.props;

        addVariantsToCart(cartName, (data) => {
            if (data) {
                updateSettings({
                    showAddToCartNotice: true,
                    lastCartName: cartLabel,
                });
            } else {
                updateSettings({
                    showAddToCartWarning: true,
                    lastCartName: cartLabel,
                });
            }
        });
    }

    addColorSampleToCart = (e) => {
        e.preventDefault();

        const {
            selectedColorState,
        } = this.state;

        this.props.orderSample(selectedColorState);
    }

    onNoSKUQtyChange = (e) => {

        e.preventDefault();
        const quantity = e.currentTarget.value;
        const qty = parseInt(quantity);

        this.setState({
            quantityofNoSKU: qty
        });
    };

    changeImage = (imageVar) => {

        const {
            onSelectedConfigColorChange,
        } = this.props;

        onSelectedConfigColorChange(imageVar);
    };

    getProperties = (steps) => {
        if (steps) {

            var string = "";

            steps.forEach(function (item, index) {

                var num = index + 1;
                if (item != null) {

                    //Get the option that is selected if it is a dropdown
                    var selectedOption = "";
                    if (item.Options != null && item.Title !== "Color") {

                        if (item.Options.length > 1) {
                            var found = item.Options.find(element => element.Selected);
                            if (found != null) {
                                selectedOption = found.Label;
                            }

                            if (string) {
                                //string = string + "&" + item.Title + "=" + selectedOption;
                                string = string + "&prop" + num + "=" + selectedOption;
                            }
                            else {
                                //string = "&" + item.Title + "=" + selectedOption;
                                string = "&prop" + num + "=" + selectedOption;
                            }

                        }


                    }



                }
            });

            return string;
        }
        else {
            return "";
        }
    }


    render() {

        const {
            steps,
            settings,
            variants,
            colorStep,
            paletteStep,
            resetVariants,
            updateVariant,
            addVariantsToQuote,
        } = this.props;

        const {
            quoteList,
            isGuestUser,
            isDiscontinued,
            isAvailableForPurchase,
            hideAddColorSampleToCartButton,
            isPortal,
            isKitLayout,
            enableDropdownDependencies,
            internetPartNumberSymbol,
            quantityofNoSKU,
            selectedColorState,
            positionPaletteColor,
            marketSegments,
            activeQuoteId,
            activeQuoteProjectName,
        } = this.state;

        const stepsToRender = steps || this.state.initialSteps || [];



        const colorStepToRender = colorStep || this.state.initialColorStep || null;
        const paletteStepToRender = paletteStep || this.state.initialPaletteStep || null;
        const variantsStepToRender = variants || this.state.initialVariants || [];

        const hasVariants = this.state.initialVariants && this.state.initialVariants.length ? true : false;
        const containerClass = hasVariants ? "width--layout--configuration" : "width--layout--configuration requestQuoteOnlySection";

        const hasSelectedColor = colorStepToRender && colorStepToRender.Options ? !!colorStepToRender.Options.find(o => o.Selected) : false;

        const hasColorWithColorSKUTie = colorStepToRender && colorStepToRender.Options ? !!colorStepToRender.Options.find(o => o.ColorAndSKUFilter != "") : false;

        const paletteStepNumber = paletteStepToRender ? stepsToRender.length + 1 : stepsToRender.length;
        const colorStepNumber = colorStepToRender ? paletteStepNumber + 1 : paletteStepNumber;
        const variantsStepNumber = colorStepNumber + 1;

        const selectedColorPalette = paletteStepToRender && paletteStepToRender.Options && paletteStepToRender.Options.length ? paletteStepToRender.Options.find(o => o.Selected) : null;
        const selectedColorPaletteName = selectedColorPalette ? selectedColorPalette.Label : [];

        const selectedColor = colorStepToRender && colorStepToRender.Options && colorStepToRender.Options.length ? colorStepToRender.Options.find(o => o.Selected) : null;
        const selectedColorName = selectedColor ? selectedColor.Label : "";
        const selectedColorId = selectedColor ? selectedColor.ColorId : "";
        const selectedColorCode = selectedColor ? selectedColor.ColorCode : "";
        const selectedColorAndSKUFilter = selectedColor ? selectedColor.ColorAndSKUFilter : "";

        if (!this.state.selectedColorState) {
            this.state.selectedColorState = selectedColor ? selectedColor.Value : "";
        }

        var filteredVariants = selectedColor && variantsStepToRender && variantsStepToRender.length ? variantsStepToRender.filter(o => o.ColorAndSKUFilter == selectedColorAndSKUFilter) : variantsStepToRender;
        if (!enableDropdownDependencies && !isKitLayout)
        {
            filteredVariants = selectedColor && variantsStepToRender && variantsStepToRender.length ? variantsStepToRender.filter(o => o.ColorAndSKUFilter == "" || o.ColorAndSKUFilter == selectedColorAndSKUFilter) : variantsStepToRender;
        }

        var filteredVariants = selectedColor && variantsStepToRender && variantsStepToRender.length ? variantsStepToRender.filter(o => o.ColorAndSKUFilter == selectedColorAndSKUFilter) : variantsStepToRender;
        if (!enableDropdownDependencies && !isKitLayout) {
            filteredVariants = selectedColor && variantsStepToRender && variantsStepToRender.length ? variantsStepToRender.filter(o => o.ColorAndSKUFilter == "" || o.ColorAndSKUFilter == selectedColorAndSKUFilter) : variantsStepToRender;
        }

        const filteredVariantsStepToRender = filteredVariants;

        const selectedVariant = filteredVariantsStepToRender && filteredVariantsStepToRender.length ? filteredVariantsStepToRender.find(o => o.Selected) : null;

        const singleVariant = filteredVariantsStepToRender && filteredVariantsStepToRender.length ? filteredVariantsStepToRender[0] : null;

        const selectedVariantItem = selectedVariant ? selectedVariant : singleVariant;

        const quantityNoSKU = quantityofNoSKU;

        if (selectedVariantItem != null) {
            if (selectedVariantItem.quantity == 0) {
                selectedVariantItem.quantity = 1;
            }
        }

        if (enableDropdownDependencies && !isKitLayout) {
            //Dependency Filtering of Options
            stepsToRender.forEach(function (item, index) {

                //Reset if no selected color
                //reset all options in dropdowns to show
                    if (selectedColor == null && item.Options != null) {

                        var options = item.Options;
                        options.forEach(function (item2, index2) {
                            stepsToRender[index].Options[index2].Show = true;
                        });

                    }

         

                if (item.Options != null) {
                    var selectedOption = item.Options.find(o => o.Selected);
                    if (selectedOption != null) {
                        if (selectedOption.NextOptions != null) {
                            if (selectedOption.NextOptions.length > 0) {

                                //Set next property's options to show or hide
                                if (stepsToRender[index + 1] != null) {
                                    if (stepsToRender[index + 1].Options != null) {

                                        var nextPropertyOptions = stepsToRender[index + 1].Options;

                                        nextPropertyOptions.forEach(function (item2, index2) {

                                            if (selectedOption.NextOptions.indexOf(item2.Value) > -1) {
                                                //If in the array, show it
                                                stepsToRender[index + 1].Options[index2].Show = true;
                                            }
                                            else {
                                                //Hide and Deselect
                                                stepsToRender[index + 1].Options[index2].Show = false;
                                                stepsToRender[index + 1].Options[index2].Selected = false;
                                            }

                                        });

                                        //if there's no selected, make the first option selected true if show = true
                                        var selectedOptionInNextDropdown = stepsToRender[index + 1].Options.find(o => o.Selected);
                                        if (selectedOptionInNextDropdown == null) {
                                            const indexOfFirstShow = stepsToRender[index + 1].Options.findIndex(a => a.Show == true);
                                            if (indexOfFirstShow > -1) {
                                                stepsToRender[index + 1].Options[indexOfFirstShow].Selected = true;
                                            }
                                        }


                                    }
                                }

                            }
                        }
                    }

                    //if a color is selected, filter options
                    if (selectedColor != null && index + 1 >= positionPaletteColor && hasColorWithColorSKUTie && !isKitLayout) {

                        item.Options.forEach(function (option, optionIndex) {

                            //if there's a color sku tie string
                            if (selectedColorAndSKUFilter) {
                                if (option.ColorandSKUFilterArray != null) {
                                    if (option.ColorandSKUFilterArray.length > 0) {


                                        const indexOfColorSKU = option.ColorandSKUFilterArray.indexOf(selectedColorAndSKUFilter);

                                        if (indexOfColorSKU > -1) {
                                            //matches so show
                                            //stepsToRender[index].Options[optionIndex].Selected = true;
                                            stepsToRender[index].Options[optionIndex].Show = true;
                                        }
                                        else {
                                            //doesn't have it, so doesn't match
                                            stepsToRender[index].Options[optionIndex].Selected = false;
                                            stepsToRender[index].Options[optionIndex].Show = false;
                                        }

                                    }
                                    else {
                                        stepsToRender[index].Options[optionIndex].Selected = false;
                                        stepsToRender[index].Options[optionIndex].Show = false;
                                    }
                                }
                            }
                            //no color sku tie string
                            else {
                                //hide options that have an array of something
                                if (option.ColorandSKUFilterArray != null) {
                                    if (option.ColorandSKUFilterArray.length > 0) {
                                        stepsToRender[index].Options[optionIndex].Selected = false;
                                        stepsToRender[index].Options[optionIndex].Show = false;
                                    }
                                }
                            }


                            //if there's no selected, make the first option selected true if show = true
                            var selectedOptionInNextDropdown = stepsToRender[index].Options.find(o => o.Selected);
                            if (selectedOptionInNextDropdown == null) {
                                const indexOfFirstShow = stepsToRender[index].Options.findIndex(a => a.Show == true);
                                if (indexOfFirstShow > -1) {
                                    stepsToRender[index].Options[indexOfFirstShow].Selected = true;
                                }
                            }


                        });
                    }
                }

            });
        }


        //Dependency for Kit layout page
        if (enableDropdownDependencies && isKitLayout) {

            stepsToRender.forEach(function (step, index) {

                if (index != 0 && step.Options != null) {

                    //Get Option of First Dropdown
                    var selectedOption = stepsToRender[0].Options.find(o => o.Selected);

                    if (selectedOption != null) {
                        if (selectedOption.BelongsToThisSKUArray != null) {
                            if (selectedOption.BelongsToThisSKUArray.length > 0) {

                                //Set options to show or hide based on sku array
                                if (stepsToRender[index] != null) {
                                    if (stepsToRender[index].Options != null) {


                                        var nextPropertyOptions = stepsToRender[index].Options;

                                        nextPropertyOptions.forEach(function (item2, index2) {

                                            //Check if option's sku array have a sku in common with the first dropdown sku array

                                            if (selectedOption.BelongsToThisSKUArray.some(item => item2.BelongsToThisSKUArray.includes(item))) {
                                                stepsToRender[index].Options[index2].Show = true;
                                                //stepsToRender[index].Options[index2].Selected = true;
                                            }
                                            else {
                                                stepsToRender[index].Options[index2].Show = false;
                                                stepsToRender[index].Options[index2].Selected = false;
                                            }

                                        });

                                        //if there's no selected, make the first option selected true if show = true
                                        var selectedOptionInNextDropdown = stepsToRender[index].Options.find(o => o.Selected);
                                        if (selectedOptionInNextDropdown == null) {
                                            const indexOfFirstShow = stepsToRender[index].Options.findIndex(a => a.Show == true);
                                            if (indexOfFirstShow > -1) {
                                                stepsToRender[index].Options[indexOfFirstShow].Selected = true;
                                            }
                                        }



                                    }
                                }

                            }
                        }
                    }

                }

            });



        }

        const finalQuoteList = settings.updatedQuoteList.length ? settings.updatedQuoteList : quoteList;

        const hideRequestQuoteButton = !isGuestUser && isAvailableForPurchase;

        return (
            <div className={containerClass}>



                {stepsToRender.map((step, i) => (

                    <div className="stepDiv">

                        {paletteStepToRender && positionPaletteColor == i + 1 ? (

                            paletteStepToRender.Options.length > 1 ? (


                                <Step
                                    number={paletteStepNumber}
                                    key={paletteStepToRender.Name}
                                    {...paletteStepToRender}
                                    onChange={this.onPaletteSelect}
                                    selectedOption={selectedColorPaletteName} />


                            ) : null

                        ) : null}

                        {colorStepToRender && positionPaletteColor == i + 1 ? (
                            <Cloak active={settings.isSampleOrdered} showSpinner>

                                <NewColorStep
                                    number={colorStepNumber}
                                    step={colorStepToRender}
                                    onChange={this.onColorSelect}
                                    isLoading={settings.isColorStepLoading}
                                    selectedColor={selectedColor}
                                    addColorSampleToCart={this.addColorSampleToCart}
                                    hideAddColorSampleToCartButton={hideAddColorSampleToCartButton}
                                    title={colorStepToRender.Options.length == 1 ? colorStepToRender.Title : "Choose " + colorStepToRender.Title}
                                />

                            </Cloak>

                        ) : null}


                        {step.Options.filter(o => o.Show == true).length > 1 ? (

                            <Step
                                number={i + 1}
                                key={step.Name}
                                Name={step.Name}
                                Title={step.Title}
                                Options={step.Options.filter(o => o.Show == true)}
                                onChange={this.onOptionChange}
                                selectedOption={step.Options.find(o => o.Selected) ? step.Options.find(o => o.Selected).Label : []} />



                        ) : null

                        }


                        {step.Options.filter(o => o.Show == true).length == 1 ? (

                            <NumberedContainer number={i + 1} singleOption={true} title={step.Title}>
                                <div className="singleOption">{step.Options.find(o => o.Selected) ? step.Options.find(o => o.Selected).Label : ""}</div>
                            </NumberedContainer>



                        ) : null

                        }

                    </div>


                ))}

                {paletteStepToRender && positionPaletteColor < 1 ? (

                    paletteStepToRender.Options.length > 1 ? (


                        <Step
                            number={paletteStepNumber}
                            key={paletteStepToRender.Name}
                            {...paletteStepToRender}
                            onChange={this.onPaletteSelect}
                            selectedOption={selectedColorPaletteName} />


                    ) : <NumberedContainer number={1} singleOption={true} title={paletteStepToRender.Title}>
                        <div className="singleOption">{paletteStepToRender.Options.find(o => o.Selected) ? paletteStepToRender.Options.find(o => o.Selected).Label : ""}</div>
                    </NumberedContainer>

                ) : null}

                {colorStepToRender && positionPaletteColor < 1 ? (
                    <Cloak active={settings.isSampleOrdered} showSpinner>

                        <NewColorStep
                            number={colorStepNumber}
                            step={colorStepToRender}
                            onChange={this.onColorSelect}
                            isLoading={settings.isColorStepLoading}
                            selectedColor={selectedColor}
                            addColorSampleToCart={this.addColorSampleToCart}
                            title={colorStepToRender.Options.length == 1 ? colorStepToRender.Title : "Choose " + colorStepToRender.Title}
                        />

                    </Cloak>

                ) : null}

                {hasVariants ? (

                filteredVariantsStepToRender.length ? (
                    <NumberedContainer number={variantsStepNumber} singleOption={filteredVariantsStepToRender.length == 1 ? true : false} title={filteredVariantsStepToRender.length == 1 ? this.state.tableLabels.variantsStepTitle : "Choose " + this.state.tableLabels.variantsStepTitle}>
                        <Cloak active={settings.isVariantsLoading} showSpinner>
                            <NewShopProductTable
                                list={filteredVariantsStepToRender}
                                labels={this.state.tableLabels}
                                minimumPurchaseAmount={this.state.minimumPurchaseAmount}
                                isColorSelected={hasSelectedColor}
                                onReset={resetVariants}
                                onAddToCart={this.onAddToCart}
                                onAddToQuote={addVariantsToQuote}
                                updateVariant={updateVariant}
                                onCloseWarning={this.onCloseWarning}
                                onCloseNotice={this.onCloseNotice}
                                showAddToCartNotice={settings.showAddToCartNotice}
                                showAddToCartWarning={settings.showAddToCartWarning}
                                lastCartName={settings.lastCartName}
                                isNewWishlist={settings.isNewWishlist}
                                quoteList={finalQuoteList}
                                showAddToQuote={!isGuestUser && isAvailableForPurchase && !isPortal}
                                steps={[...stepsToRender, paletteStepToRender, colorStepToRender]}
                                onChange={this.onVariantSelect}
                                selectedVariantItem={selectedVariantItem}
                                isDiscontinued={isDiscontinued}
                                isAvailableForPurchase={isAvailableForPurchase}
                                internetPartNumberSymbol={internetPartNumberSymbol}
                                activeQuoteId={activeQuoteId}
                                activeQuoteProjectName={activeQuoteProjectName}
                                selectedColorPaletteName={selectedColorPaletteName}
                                selectedColorName={selectedColorName}
                                selectedColorId={selectedColorId}
                                selectedColorCode={selectedColorCode}
                                marketSegments={marketSegments}
                            />
                        </Cloak>
                    </NumberedContainer>
                ) : <NumberedContainer title={this.state.tableLabels.variantsStepTitle}>
                        <Cloak active={settings.isVariantsLoading} showSpinner>
                            <div className="noPartNumberSection">
                                {this.state.tableLabels.noPartNumberMessage}
                            </div>

                                <div className="speedShopProductColumn product__NoSKUQuantity">

                                    <label htmlFor={"quantity-nosku"}>{"Quantity"}</label>

                                    <input
                                        id={"quantity-nosku"}
                                        type="text"
                                        name="Quantity"
                                        value={quantityNoSKU > 0 ? quantityNoSKU : ''}
                                        min="1"
                                        max="9999"
                                        placeholder="0"
                                        step="1"
                                        className="width--input-narrow"
                                        onChange={this.onNoSKUQtyChange} />
                                </div>

                                {
                                    isAvailableForPurchase ? (
                                        <div>
                                            <button
                                                type="addToCart"
                                                className="button button--solid product__addToCartButton2"
                                                disabled={true}
                                            >{this.state.tableLabels.addTo.prefix} {this.state.tableLabels.addTo.cart}</button>

                                            <a href={'/shopping-cart/'} className="button product__viewCartButton">View Cart</a>
                                        </div>
                                    ) : null
                                }



                                {

                                !hideRequestQuoteButton && !isDiscontinued && this.state.tableLabels.requestAQuote && this.state.tableLabels.requestAQuoteLink ? (
                                    <a href={quantityNoSKU ? this.state.tableLabels.requestAQuoteLink
                                        + "&quantity=" + quantityNoSKU + (selectedColorPaletteName && selectedColorName ? '&palette=' + selectedColorPaletteName : '') +
                                        (selectedColorName ? '&color=' + selectedColorName : '')
                                        + this.getProperties(steps)
                                        : this.state.tableLabels.requestAQuoteLink + this.getProperties(steps) } className="button primary product__RequestQuote">{this.state.tableLabels.requestAQuote}</a>
                                ) : null
                            }

                            </Cloak>
                        </NumberedContainer>
                    

                ) : !isDiscontinued && this.state.tableLabels.requestAQuote && this.state.tableLabels.requestAQuoteLink ? (
                        <a href={this.state.tableLabels.requestAQuoteLink + this.getProperties(steps)} className="button primary product__RequestQuote product__RequestQuoteOnly">{this.state.tableLabels.requestAQuote}</a>
                ) : null}


            </div>
        );

    }

}

const mapProps = (state) => {
    return {
        settings: state.productPage.settings,
        steps: state.productPage.config.steps,
        colorStep: state.productPage.config.colorStep,
        paletteStep: state.productPage.config.paletteStep,
        variants: state.productPage.variants,
    };
}

const mapDispatch = (dispatch) => {
    return {
        setSteps: (list) => dispatch(setProductConfigSteps(list)),
        setColorStep: (step) => dispatch(setProductConfigColorStep(step)),
        setPaletteStep: (step) => dispatch(setProductConfigPaletteStep(step)),
        setVariants: (list) => dispatch(setProductVariantsList(list)),
        selectColor: (value, showStockAvailability) => dispatch(selectProductConfigColorStepOption(value, showStockAvailability)),
        selectPalette: (value) => dispatch(selectProductConfigPaletteStepOption(value)),
        selectStepOption: (step, value) => dispatch(selectProductConfigStepOption(step, value)),
        updateVariant: (variant) => dispatch(updateProductPageVariant(variant)),
        resetVariants: () => dispatch(resetProductPageVariantList()),
        addVariantsToCart: (cartName, cb) => dispatch(addProductPageVariantsToCart(cartName, cb)),
        addVariantsToQuote: (quoteId, projectName, zipCode, marketSegment) => dispatch(addProductPageVariantsToQuote(quoteId, projectName, zipCode, marketSegment)),
        updateSettings: (settings) => dispatch(updateProductPageSettings(settings)),
        selectVariant: (variant) => dispatch(updateProductPageVariant(variant)),
        orderSample: (code) => dispatch(orderSample(code)),
    };
}

NewConfiguration = connect(mapProps, mapDispatch)(NewConfiguration);

export default (props) => (
    <InproProvider>
        <NewConfiguration {...props} />
    </InproProvider>
);