import fetch from 'cross-fetch';

import {
  SET_PRODUCT_PAGE_DATA,
  UPDATE_PRODUCT_PAGE_ASSETS,
  UPDATE_PRODUCT_PAGE_SETTINGS,
  SET_PRODUCT_PAGE_CONFIG_STEPS,
  SET_PRODUCT_PAGE_VARIANTS_LIST,
  SET_PRODUCT_PAGE_CONFIG_COLORS,
  SET_PRODUCT_PAGE_CONFIG_PALETTES,
  UPDATE_PRODUCT_PAGE_CONFIG_COLORS,
  UPDATE_PRODUCT_PAGE_CONFIG_PALETTES_OPTIONS,
} from './types';

import {
  commonPost,
} from '../apiHelper';

import {
  getColors,
} from '../services/productApiService';

const apiMethods = {
  variants: '/api/Products/GetSortedVariants',
    addToCart: '/AddToCart/AddToCartService',
    addProductsToCRMQuote: '/QuotesService/AddProductsToCRMQuote',
    updateQuoteList: '/QuotesService/UpdateQuoteList',
}

const getSortedVariants = (productCode, selectedOptions) => commonPost(apiMethods.variants, {
  productCode,
  selectedOptions,
});

const collectOptions = (state) => {
  const {
    productPage: {
      config: {
        steps,
        colorStep,
        paletteStep,
      }
    },
  } = state;

  const newSteps = [...steps];

  if (colorStep) {
    newSteps.push(colorStep);
  }
  
  if (paletteStep) {
    newSteps.push(paletteStep);
  }
  
  return newSteps.map(({ Name, Options }) => {
      if (!Options) {
        return null;
      }
      
      const option = Options.find(option => option.Selected);
      if (option) {
        return {
          Name,
          Value: option.Value,
        }
      }
      return null;
    })
    .filter(option => option);
}

export const setProductPageData = (data) => {
  return {
    type: SET_PRODUCT_PAGE_DATA,
    data,
  }
}

export const setProductConfigSteps = (list) => {
  return {
    type: SET_PRODUCT_PAGE_CONFIG_STEPS,
    list,
  }
}

export const selectProductConfigStepOption = (stepName, selectedOption) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        config,
        product,
      },
    } = getState();

    const newSteps = config.steps ? config.steps.map(step => {
      if (step.Name === stepName) {
        return Object.assign({}, step, {
          Options: step.Options.map(option => {
            option = Object.assign({}, option, {
              Selected: option.Value === selectedOption
            });
            return option;
          })
        })
      } else {
        return step;
      }
    }) : config.steps;

    dispatch(setProductConfigSteps(newSteps));
    dispatch(updateProductPageSettings({
      isVariantsLoading: true,
      isColorStepLoading: true,
    }));

    getSortedVariants(product.Code, collectOptions(getState())).then(data => {
      if (data && data.variants) {
        dispatch(setProductVariantsList(data.variants));
      } 
      if (data && data.palletsOptions && data.palletsOptions.length) {
        dispatch(updateProductConfigPaletteStepOptions(data.palletsOptions));
        getColorsAndUpdateState(data.palletsOptions, dispatch, product.Code);
      } else {
        dispatch(updateProductPageSettings({
          isColorStepLoading: false,
        }));
      }
      dispatch(updateProductPageSettings({
        isVariantsLoading: false,
      }));
    })

  }
}

export const setProductConfigColorStep = (step) => {
  return {
    type: SET_PRODUCT_PAGE_CONFIG_COLORS,
    step,
  }
}

export const setProductConfigColorStepColors = (colors) => {
  return {
    type: UPDATE_PRODUCT_PAGE_CONFIG_COLORS,
    colors,
  }
}

export const updateProductConfigColorStepColors = (colors) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        config: {
          colorStep,
        }
      }
    } = getState();

    const selectedColor = colorStep.Options.find(c => c.Selected);

    if (selectedColor) {
      colors = colors.map(c => {
        if (selectedColor.Value === c.Value) {
          return Object.assign({}, c, {
            Selected: true,
          })
        }

        return c;
      })
    } 

    dispatch(setProductConfigColorStepColors(colors));
  }
}

export const selectProductConfigColorStepOption = (value, showStockAvailability) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        config,
            product,
        variants,
      },
    } = getState();

    const newColorStep = config.colorStep ? Object.assign({}, config.colorStep, {
      Options: config.colorStep.Options.map(option => {
        option = Object.assign({}, option, {
          Selected: option.Value === value
        });
        return option;
      })
    }) : config.colorStep;

      dispatch(setProductConfigColorStep(newColorStep));

      if (showStockAvailability) {
          dispatch(updateProductPageSettings({
              isVariantsLoading: true,
          }));

          const selectedVariant = variants ? variants.find(v => v.Selected) : null;
          const quantity = selectedVariant ? 1 : 0;
          const Code = selectedVariant ? selectedVariant.Code : "";

          const Selected = selectedVariant ? selectedVariant.Selected : false;

          getSortedVariants(product.Code, collectOptions(getState())).then(data => {
              if (data && data.variants) {
                  dispatch(setProductVariantsList(data.variants));

                  //Keep Selected Variant Quantity as 1 and Selected as True
                  if (selectedVariant) {
                      dispatch(updateProductPageVariant(({
                          quantity,
                          Code,
                          Selected
                      })));
                  }

              }
              if (data && data.palletsOptions) {
                  dispatch(updateProductConfigPaletteStepOptions(data.palletsOptions));
                  //getColorsAndUpdateState(data.palletsOptions, dispatch);
              }
              dispatch(updateProductPageSettings({
                  isVariantsLoading: false,
              }));
          })
      }


  }
}

export const setProductConfigPaletteStep = (step) => {
  return {
    type: SET_PRODUCT_PAGE_CONFIG_PALETTES,
    step,
  }
}

export const updateProductConfigPaletteStepOptions = (options) => {
  return {
    type: UPDATE_PRODUCT_PAGE_CONFIG_PALETTES_OPTIONS,
    options,
  }
}

export const selectProductConfigPaletteStepOption = (value, cb) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        config,
        product,
      },
    } = getState();

    const newPaletteStep = config.paletteStep ? Object.assign({}, config.paletteStep, {
      Options: config.paletteStep.Options.map(option => {
        option = Object.assign({}, option, {
          Selected: option.Value === value
        });
        return option;
      })
    }) : config.paletteStep;

    dispatch(setProductConfigPaletteStep(newPaletteStep));
    dispatch(updateProductPageSettings({
      isVariantsLoading: true,
      isColorStepLoading: true,
    }));
    
    getSortedVariants(product.Code, collectOptions(getState())).then(data => {
      if (data && data.variants) {
        dispatch(setProductVariantsList(data.variants));
      }
      if (data && data.palletsOptions) {
        dispatch(updateProductConfigPaletteStepOptions(data.palletsOptions));
        getColorsAndUpdateState(data.palletsOptions, dispatch, product.Code);
      } else {
        dispatch(updateProductPageSettings({
          isColorStepLoading: false,
        }))
      }
      dispatch(updateProductPageSettings({
        isVariantsLoading: false,
      }));
    })

  }
}

const getColorsAndUpdateState = (options, dispatch, productCode) => {
  const selectedPalette = options.find(o => o.Selected);
  if (selectedPalette) {
    dispatch(updateProductPageSettings({
      isColorStepLoading: true,
    }));
    getColors(selectedPalette.Value, productCode).then(colors => {
      if (colors) {        
        dispatch(updateProductConfigColorStepColors(colors));
      }
      dispatch(updateProductPageSettings({
        isColorStepLoading: false,
      }));
    });
  }
}

export const setProductVariantsList = (list = []) => {  
  return {
    type: SET_PRODUCT_PAGE_VARIANTS_LIST,
    list,
  }
};

export const updateProductPageVariant = (variant) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        variants,
      }
    } = getState();

    const newVariants = variants.map(v => {
      if (v.Code === variant.Code) {
        return Object.assign({}, v, variant);
      }

      return v;
    })

    dispatch(setProductVariantsList(newVariants));
  }
}

export const resetProductPageVariantList = (variant) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        variants,
      }
    } = getState();

    const newVariants = variants.map(v => Object.assign({}, v, {
        quantity: 0,
        Selected: false,
    }));

    dispatch(setProductVariantsList(newVariants));
  }
}

export const updateProductPageSettings = (settings) => ({
  type: UPDATE_PRODUCT_PAGE_SETTINGS,
  settings,
})

const getSelectedColorAndVariants = (getState) => {
  const {
    productPage: {
      config: {
        colorStep,
      },
      variants,
    }
  } = getState();

  return {
    variants: variants.filter(v => v.quantity),
    selectedColor: colorStep ? colorStep.Options.find(c => c.Selected) : null,
  };
}

export const addProductPageVariantsToCart = () => (dispatch, getState) => {    
  const {
    variants,
    selectedColor,
  } = getSelectedColorAndVariants(getState);

  const CartName = "ShoppingCart";

    const CartItems = variants.map(({ Code, quantity, HasNoColor}) => ({
    Code,
    CartName,
    Quantity: quantity,
    Metafields: {
        ColorCode: selectedColor && !HasNoColor ? selectedColor.Value : null,
    },
    WaiveMinIncrCheck: true,
  }));

  dispatch(updateProductPageSettings({
    isVariantsLoading: true,
    isNewWishlist: false,
  }));

  commonPost(apiMethods.addToCart, CartItems).then(data => {
    window.WOW.MiniSwatchCart.refresh();
      window.WOW.MiniCart.refresh();


      $("#miniSlideOutCart__Image").attr("src", variants[0].ParentImageURL +"?width=125&height=125&mode=crop");
      $("#miniSlideOutCart__ProductTitle").text(variants[0].ParentName);
      $("#miniSlideOutCart__Code").text(data[0].Code);
      $("#miniSlideOutCart__Size").text(variants[0].Specs);

      if (selectedColor && !variants[0].HasNoColor) {
          $("#miniSlideOutCart__StyleSection").show();

          $("#miniSlideOutCart__Style").text(selectedColor && !variants[0].HasNoColor ? selectedColor.Label : "");
          $("#miniSlideOutCart__StyleImage").attr("src", selectedColor && selectedColor.Image && !variants[0].HasNoColor ? selectedColor.Image.ImageURL + "?width=200&height=200&mode=crop" : "");
      }
      else {
          $("#miniSlideOutCart__StyleSection").hide();
      }
     


      $("#miniSlideOutCart__Qty").text(data[0].Quantity);
      $("#miniSlideOutCart__Price").text(data[0].PlacedPrice);

      var subtotalProduct = data[0].Quantity * data[0].PlacedPrice;
      $("#miniSlideOutCart__Subtotal").text(subtotalProduct.toFixed(2));

      window.WOW.MiniSlideOutCart.refresh();


    //dispatch(resetProductPageVariantList());
    dispatch(updateProductPageSettings({
      isVariantsLoading: false,
      lastCartName: CartName,
    }));
    if (data) {

        $('#cd-shadow-layer').addClass('is-visible');
        $('#miniSlideOutCart').addClass('slide-in');
        document.body.classList.add("no-scroll");


    } else {
      dispatch(updateProductPageSettings({
        showAddToCartWarning: true,
      }));
    }
  });

}

export const addProductPageVariantsToQuote = (quoteId, projectName, zipCode, marketSegment) => (dispatch, getState) => {
  const {
    variants,
    selectedColor,
  } = getSelectedColorAndVariants(getState);


    const QuoteProductList = variants.map(({ Code, quantity, HasNoColor, Price, Specs }) => ({
    
        ItemNumber: Code,
        QuoteID: quoteId,
        Quantity: quantity,
        UnitPrice: Price,
        TotalPrice: Price,
        Color: selectedColor && !HasNoColor ? selectedColor.ColorId : null,
        Description: Specs


    }));

  dispatch(updateProductPageSettings({
    isVariantsLoading: true,
    isNewWishlist: false,
  }));

    commonPost(apiMethods.addProductsToCRMQuote, { quoteId, projectName, QuoteProductList, zipCode, marketSegment }).then(data => {
    
    let lastCartName = '';    

    if (data) {


        if (data.Success.Item2) {
            lastCartName = data.Success.Item2;
        }

        //Update Mini Quote Hover
        window.WOW.MiniQuoteCart.refresh();

        //Update Quote List
        commonPost(apiMethods.updateQuoteList).then(data2 => {

            if (data2) {
                
                dispatch(updateProductPageSettings({
                    updatedQuoteList: data2
                }));

            }

            dispatch(updateProductPageSettings({
                isVariantsLoading: false,
                lastCartName,
            }));
            if (data && data.Success.Item1) {
                dispatch(updateProductPageSettings({
                    showAddToCartNotice: true,
                }));
            } else {
                dispatch(updateProductPageSettings({
                    showAddToCartWarning: true,
                }));
            }

        });

    }
    
    

  });
}

export const updateProductPageAsset = (Code, values) => {
  return (dispatch, getState) => {
    const {
      productPage: {
        product: {
          AssociatedAssets,
        }
      }
    } = getState();

    const newAssets = AssociatedAssets.map((asset) => {
      if (asset.Code === Code) {
        return Object.assign({}, asset, values);
      } 

      return asset;
    });

    dispatch({
      type: UPDATE_PRODUCT_PAGE_ASSETS,
      assets: newAssets,
    });
  }
}

export const orderSample = (Code) => (dispatch, getState) => {

    const {
        variants,
        selectedColor,
    } = getSelectedColorAndVariants(getState);


    const CartItems = [{
        Code,
        CartName: 'SwatchCart',
        Quantity: 1,
        Metafields: {},
        WaiveMinIncrCheck: true,
    }];

    dispatch(updateProductPageSettings({
        isSampleOrdered: true,
    }));

    let successBool = true;

    commonPost(apiMethods.addToCart, CartItems).then(data => {
        if (data && data.length) {
            window.WOW.MiniSwatchCart.refresh();

            const messages = data.filter(cartItem => cartItem.ResultMessage).map(function (cartItem) {

                if (!cartItem.Success) {
                    successBool = false;
                }

                return {
                    message: cartItem.ResultMessage,
                    isWarning: !cartItem.Success,
                }

            }


            );

            if (!successBool) {
                window.notificationService.showList(messages, true);
            }
            else {

                try {
                    $("#miniSlideOutSwatchCart__ProductTitle").text(selectedColor.Label);
                    $("#miniSlideOutSwatchCart__Code").text(selectedColor.ColorId);
                    $("#miniSlideOutSwatchCart__Image").attr("src", selectedColor.Image.ImageURL + "?width=125&height=125&mode=crop");

                    $("#miniSlideOutSwatchCart__ImageHref").attr("href", selectedColor.ParentURL);
                    $("#miniSlideOutSwatchCart__ProductTitle").attr("href", selectedColor.ParentURL);
                    $("#miniSlideOutSwatchCart__Code").attr("href", selectedColor.ParentURL);


                    $("#miniSlideOutSwatchCart__Qty").text("1");

                    $('#cd-shadow-layer').addClass('is-visible');
                    $('#miniSlideOutSwatchCart').addClass('slide-in');
                    document.body.classList.add("no-scroll");
                }
                catch (err) {
                    window.notificationService.showList(messages, true);
                }

            }





        }

        dispatch(updateProductPageSettings({
            isSampleOrdered: false,
        }));
    });

};


export const orderSampleOnAsset = (Asset) => (dispatch, getState) => {

  const CartItems = [{
    Code: Asset.Code,
    CartName: 'SwatchCart',
    Quantity: 1,
    Metafields: {},
    WaiveMinIncrCheck: true,
  }];

    dispatch(updateProductPageSettings({
        isSampleOrdered: true,
    }));

    let successBool = true;

  commonPost(apiMethods.addToCart, CartItems).then(data => {
    if (data && data.length) {
      window.WOW.MiniSwatchCart.refresh();

        const messages = data.filter(cartItem => cartItem.ResultMessage).map(function (cartItem) {

            if (!cartItem.Success) {
                successBool = false;
            }

            return {
                message: cartItem.ResultMessage,
                isWarning: !cartItem.Success,
            }

        }


        );

        if (!successBool) {
            window.notificationService.showList(messages, true);
        }
        else {

            try {
                $("#miniSlideOutSwatchCart__ProductTitle").text(Asset.Name);

                if (Asset.ColorId) {
                    $("#miniSlideOutSwatchCart__Code").text(Asset.ColorId);
                }
             
                $("#miniSlideOutSwatchCart__Image").attr("src", Asset.Image.ImageURL + "?width=125&height=125&mode=crop");

                $("#miniSlideOutSwatchCart__ImageHref").removeAttr("href");
                $("#miniSlideOutSwatchCart__ProductTitle").removeAttr("href");
                $("#miniSlideOutSwatchCart__Code").removeAttr("href");
                //$("#miniSlideOutSwatchCart__ImageHref").attr("href", selectedColor.ParentURL);
                //$("#miniSlideOutSwatchCart__ProductTitle").attr("href", selectedColor.ParentURL);
                //$("#miniSlideOutSwatchCart__Code").attr("href", selectedColor.ParentURL);


                $("#miniSlideOutSwatchCart__Qty").text("1");

                $('#cd-shadow-layer').addClass('is-visible');
                $('#miniSlideOutSwatchCart').addClass('slide-in');
                document.body.classList.add("no-scroll");
            }
            catch (err) {
                window.notificationService.showList(messages, true);
            }
           
        }
        




      }

      dispatch(updateProductPageSettings({
          isSampleOrdered: false,
      }));
  });

};
