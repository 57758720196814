import React from 'react';
import Select, { Option } from 'rc-select';
import RadioButton from './../../Common/RadioButton';
import RadioGroup from './../../Common/RadioGroup';
import NumberedContainer from './../../Common/NumberedContainer/';

const Step = ({
  Name,
  Title,
  number,
  Options,
  onChange,
  selectedOption,
}) => (
  <NumberedContainer number={number} title={"Choose "+Title}>
    
    <Select 
        onChange={(value) => {
                onChange(Name, value);
            }}
            optionFilterProp="children"
            optionLabelProp="children"
            placeholder="Please Select"
            showSearch={false}
            showArrow={true}
            value={selectedOption}
     >

      {Options.map((option, i) => (
        <Option 
              value={option.Value}
              key={i} selected={option.Selected}>{option.Image ? <img className="kitlayout-dropdown-image" src={option.Image.ImageURL + "?width=50&amp;height=50&amp;mode=crop"}></img> : null}{option.Label}</Option>
      ))}      

    </Select>

  </NumberedContainer>
);

export default Step;